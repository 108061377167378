import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { Loader } from "rsuite";
import "./App.css";
import "./styles/Nrjwtr.css";
import { NrjRequire } from "./utilities/NrjRequire";
import { useIdleTimer } from 'react-idle-timer'
import SwitchLayout from "./components/SwitchLayout";


const LzIndiaHeatMapValue = React.lazy(() => import('./app/Reports/IndiaHeatMapValues'));

const LzNrjLgn = React.lazy(() => import("./login/NrjLogin"));
const LzAllStateReport = React.lazy(() => import("./app/AnnualRpt/AllStateReport"));

const LzChangePasswordHcf = React.lazy(() => import("./app/login/ChangePasswodHcf"));
const LzChangePasswordCbwtf = React.lazy(() => import("./app/login/ChangepasswordCbwtf"));
//const LzChangePswSpcb =  React.lazy(() => import("./app/login/ChangePswSpcb"));
const LzChangePassword = React.lazy(() => import("./app/login/ChangePasswod"));
const LzCityHcfList = React.lazy(() => import("./app/MIS/CityHcfList"));
const LzRgDrct = React.lazy(() => import("./app/brds/RgnDirctr"));
const LzRgnDirtLst = React.lazy(() => import("./app/brds/RgnDirctrLst"));
const LzSttDircctrLst = React.lazy(() => import("./app/brds/SttDirctrLst"));
const LzStateBrd = React.lazy(() => import("./app/brds/StateBrd"));
const LzCpcbHo = React.lazy(() => import("./app/brds/CpchHoPg"));
const LzAnnlMisc = React.lazy(() => import("./app/AnnualRpt/AnnlMisc"));
const LzAnnlEqp = React.lazy(() => import("./app/AnnualRpt/AnnlEqp"));
const LzAnnlWstStrg = React.lazy(() => import("./app/AnnualRpt/AnnlWstStrg"));
const LzAnnlWstWt = React.lazy(() => import("./app/AnnualRpt/AnnlWstWt"));
const LzHcfNonVisited = React.lazy(() => import("./app/Reports/MisHcfNonVisited"));
const LzListCbwtfWstData = React.lazy(() => import("./app/Reports/ListCbwtfWstData"));
const LzHCFCnt = React.lazy(() => import("./app/Reports/HcfCount"));
const LzGridDisply = React.lazy(() => import("./app/Reports/GridDisplay"));
const LzCbwtfLstRep = React.lazy(() => import("./app/Reports/CbwtfLst"));
const LzBigBag = React.lazy(() => import("./app/Reports/BigBag"));
const LzWrongWstBgCbwtf = React.lazy(() => import("./app/Reports/WrongWstBgCbwtf"));
const LzListHCF = React.lazy(() => import("./app/Reports/ListHCF"));
// const LzStt_Annlauth = React.lazy(() => import("./app/AnnualRpt/State/Stt_Annlauth"));
// const LzStt_Annldstr = React.lazy(() => import("./app/AnnualRpt/State/Stt_Annldstr"));
// const LzStt_Annlcptv = React.lazy(() => import("./app/AnnualRpt/State/Stt_Annlcptv"));
// const LzAnnCbwtf = React.lazy(() => import("./app/AnnualRpt/State/Stt_Annlcbwtf"));
// const LzAnnlFrst = React.lazy(() => import("./app/AnnualRpt/State/Stt_Annlfrst"));
const LzAnnlRpt = React.lazy(() => import("./app/AnnualRpt/AnnlRpt"));
const LzNrjForgotPassword = React.lazy(() => import("./login/NrjForgotPassword"));
const LzMisBagcount = React.lazy(() => import("./app/Reports/MisBagCountLbl"));
const LzSerialNumber = React.lazy(() => import("./app/Reports/MisSerialNumber"));
const LzMisBagcntwthGeo = React.lazy(() => import("./app/Reports/MisBagCntwthGeo"));
const LzBagCntPrHr = React.lazy(() => import("./app/dshbrd/BagCntPrHr"));
const LzBagCntPrHrGrid = React.lazy(() => import("./app/dshbrd/bagCnthrGrid"));
const LzBhuvanMap = React.lazy(() => import("./app/Reports/BhuvanMap"));
const LzVechilceTrackingMap = React.lazy(() => import("./app/Reports/VehicleLocation"))
const LzGoogleMap = React.lazy(() => import("./app/Reports/GoogleMap"));
const LzHelpPage = React.lazy(() => import("./app/Reports/HelpPage"));
const LzClrHcfWst = React.lazy(() => import("./app/Reports/ClrHcfWst"));
const LzHcf_Wstbg = React.lazy(() => import("./app/Reports/HCF_Wstbg"));
const LzSrch_hcf = React.lazy(() => import("./app/Reports/Srch_hcf"));
const LzHcfctgCnt = React.lazy(() => import("./app/dshbrd/HcfCtgCnt"));
const LzHcfctgCntGrid = React.lazy(() => import("./app/dshbrd/HcfCtgCntGrid"));
const LzBrdActvSmry = React.lazy(() => import("./app/MIS/BrdActvSmry"));
const LzWstbgOdd = React.lazy(() => import("./app/Reports/WstbgOdd"));
const LzFind_HCF = React.lazy(() => import("./app/Reports/Find_HCF"));
const LzWstbgid = React.lazy(() => import("./app/Reports/WastebagId"));
const Lzcbwtfdlyrep = React.lazy(() => import("./app/Reports/cbwtfdlyrep"));
const LzDisplayDataCard = React.lazy(() => import("./app/Reports/DisplayDataCard"));
const LzDailyReports = React.lazy(() => import("./app/Reports/DailyReport/DailyReports"));
const LzBagWeightChart30 = React.lazy(() => import("./app/dshbrd/DashboardChartView30"));
const LzBagWeightChart50 = React.lazy(() => import("./app/dshbrd/DashboardChartView50"));
const LzBagWeightChart3050 = React.lazy(() => import("./app/dshbrd/DashboardChartView3050"));
const LzBagWeightChart3050Abv = React.lazy(() => import("./app/dshbrd/DashboardChartView3050Abv"));
const LzBagWeightChartScnBy = React.lazy(() => import("./app/dshbrd/DashboardChartViewscnBy"));
const LzBagWeightChartScnBy50 = React.lazy(() => import("./app/dshbrd/DashboardChartViewscnBy50"));
const LzBagWeightChartScnBy3050 = React.lazy(() => import("./app/dshbrd/DashboardChartViewscnBy3050"))
const LzBagWeightChartScnBy3050Abv = React.lazy(() => import("./app/dshbrd/DashboardChartViewscnBy3050Abv"));
const LzBagWeightChartDate = React.lazy(() => import("./app/dshbrd/DashBoardChartDate"));
const LzBagCbwtfScnBy = React.lazy(() => import("./app/dshbrd/bagCbwtfScnBy"));
const LzBagFactory = React.lazy(() => import("./app/dshbrd/bagFactory"));

const LzAuthorizationDetail = React.lazy(() => import("./app/AnnualRpt/State/Stt_AuthorizationDetail"));
const LzCaptiveInformation = React.lazy(() => import("./app/AnnualRpt/State/Stt_CaptiveInformation"));
const LzWasteInformation = React.lazy(() => import("./app/AnnualRpt/State/Stt_WasteInformation"));
const LzDashBoardNew = React.lazy(() => import("./app/dshbrd/DashBoardNew"));
const LzHcfUnblockUser = React.lazy(() => import("./app/Reports/HcfUnblockUser"));
const LzChangePswRgd = React.lazy(() => import("./app/login/changePswRgd"));
const LzChangePswSpcb = React.lazy(() => import("./app/login/changePswSbcb"));
const LzHcfSignup = React.lazy(() => import("./app/hcf/HcfSignup"));
const LzHcfRemark = React.lazy(() => import("./app/hcf/HcfRemark"));
const LzHospitalModified = React.lazy(() => import("./app/Reports/HospitalModified"));
const LzLoginFiles = React.lazy(() => import("./app/Reports/LoginFiles"));
const LzHcfMaster = React.lazy(() => import("./app/hcf/HcfMaster"));
const LzHcfAr = React.lazy(() => import("./app/hcf/HcfAr"));
const LzHcfArDaily = React.lazy(() => import("./app/hcf/HcfArDaily"));
const LzHcfCorrectedList = React.lazy(() => import("./app/Reports/HcfCorrectedList"));
const LzAuthorizationAndWaste = React.lazy(() => import("./app/AnnualRpt/State/Stt_AnnualReportForm"));
const LzAuthorizationAndConsoliDate = React.lazy(() => import('./app/AnnualRpt/State/Stt_ConsolidateData'))
const LzAuthorizationAndWasteCpcb = React.lazy(() => import("./app/AnnualRpt/State/stt_AnnlRptCpcb"));
const LzSttMonthlyRptCpcb = React.lazy(() => import("./app/AnnualRpt/State/Stt_MonthlyRptCpcb"));


const LzStateMonthlyReport = React.lazy(() => import("./app/AnnualRpt/State/SttMonthlyReport"));
const LzAuthorHcfRegiterIndpnt = React.lazy(() => import("./app/hcf/HcfRegisterIndpnt"));
const LzAuthorHcfConsentList = React.lazy(() => import("./app/hcf/HcfConsentList"));
const LzAuthorHcfConsentReport = React.lazy(() => import("./app/hcf/HcfConsentReport"));
const LzHcfAnnlPrt = React.lazy(() => import("./app/hcf/HcfAnnlRpt"));
const LzHcfAnnlPrtFormTab = React.lazy(() => import("./app/hcf/HcfAnnlRptFormTab"))
const LzHcfAnnlPrtCpcb = React.lazy(() => import("./app/hcf/HcfAnnlRptCpcb"))
const LzHcfMonthlyPrtCpcb = React.lazy(() => import("./app/hcf/HcfMonthlyRptCpcb"))
const LzHcfMonthlyReport = React.lazy(() => import('./app/hcf/HcfMonthlyReport'))
const LzHcfMonthlyReportFormTab = React.lazy(() => import('./app/hcf/HcfMonthlyRptFormTab'))
const LzCbwtfAnnulRpt = React.lazy(() => import("./app/dshbrd/CbwtfAnnulRpt"));
const LzCbwtfAnnulRptCpcb = React.lazy(() => import("./app/dshbrd/CbwtfAnnlRptCpcb"));
const LzCbwtfMonthlyRptCpcb = React.lazy(() => import("./app/dshbrd/CbwtfMonthlyRptcpcb"));
const LzCbwtfDetails = React.lazy(() => import("./app/dshbrd/CbwtfDetails"));
const LzCbwtfMonthlyRpt = React.lazy(() => import('./app/dshbrd/CbwtfMonthlyRpt'))


function App() {
  document.title = "CPCB";
  const [state, setState] = useState<string>('Active')
  const [count, setCount] = useState<number>(0)
  const [remaining, setRemaining] = useState<number>(0)
  const navigate = useNavigate();
  function signout() {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/login");
  }

  const onIdle = () => {
    setState('Idle')
    signout();
  }

  const onActive = () => {
    setState('Active')
  }

  const onAction = () => {
    setCount(count + 1)
  }

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: 1200000, //20 minute 
    throttle: 500
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, 500)

    return () => {
      clearInterval(interval)
    }
  })


  const openHelpPage = () => {
    const pdfUrl = "pdfs/" + window.location.pathname.replace("/", "") + ".pdf";
    window.open(pdfUrl, "_blank");
  };


  return (


    <div style={{ backgroundColor: "#F5F5F5" }}>
      <Routes>
        <Route
          index
          // path="/login"
          element={
            <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
              <LzNrjLgn />
            </React.Suspense>
          }
        ></Route>
        <Route
          path="/forgotPassword"
          element={
            <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
              <LzNrjForgotPassword />
            </React.Suspense>
          }
        ></Route>

        <Route
          path="/login"
          element={
            <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
              <LzNrjLgn />
            </React.Suspense>
          }
        ></Route>
        <Route
          path="/hcfMaster"
          element={
            <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
              <LzHcfSignup></LzHcfSignup>
            </React.Suspense>
          }
        ></Route>
        <Route
          path="/hcfAr"
          element={
            <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
              <LzHcfAr></LzHcfAr>
            </React.Suspense>
          }
        ></Route>
        <Route
          path="/hcfArDaily"
          element={
            <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
              <LzHcfArDaily></LzHcfArDaily>
            </React.Suspense>
          }
        ></Route>
        <Route
          path="/hcfAnnlRpt"
          element={
            <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
              <LzHcfAnnlPrt></LzHcfAnnlPrt>
            </React.Suspense>
          }
        ></Route>
        <Route
          path="/hcfRemark"
          element={
            <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
              <LzHcfRemark></LzHcfRemark>
            </React.Suspense>
          }
        ></Route>
        {/* <Route
          path="/ChangePasswordHcf"
          element={
            <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
              <LzChangePasswordHcf></LzChangePasswordHcf>
            </React.Suspense>
          }
        ></Route> */}


        <Route
          path="/cbwtfMonthlyRpt"
          element={
            <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
              <NrjRequire>
                <LzCbwtfMonthlyRpt />
              </NrjRequire>
            </React.Suspense>
          }
        ></Route>
        {/* <Route
          path="/cbwtfDtl"
          element={
            <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
              <NrjRequire>
                <LzCbwtfDetails />
              </NrjRequire>
            </React.Suspense>
          }
        ></Route> */}
        <Route
          path="/"
          element={
            <SwitchLayout />
          }>
          <Route
            path="/mapIndia"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <LzIndiaHeatMapValue></LzIndiaHeatMapValue>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/ChangePasswordHcf"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <LzChangePasswordHcf></LzChangePasswordHcf>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/ChangePasswordCbwtf"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <LzChangePasswordCbwtf></LzChangePasswordCbwtf>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/cbwtfAnnulRpt"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzCbwtfAnnulRpt />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/hcfDetails"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <LzHcfMaster></LzHcfMaster>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/hcfAnnlRptFormTab"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <LzHcfAnnlPrtFormTab></LzHcfAnnlPrtFormTab>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/cbwtfDtl"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzCbwtfDetails />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>

          <Route
            path="/cbwtfdspl"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzCbwtfLstRep></LzCbwtfLstRep>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>

          <Route
            path="/hcflist"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzListHCF
                    path={"hcflist"}
                    inputData={{ lvl: "CPCB", who: "", dtno: "0" }}
                    cols={[
                      // {
                      //   field: "cbwtfid",
                      //   hidden: true,
                      //   width: 50,
                      //   headerName: "S No",
                      // },
                      {
                        field: "hcfnm",
                        width: 320,
                        headerName: "HCF",
                        filter: "agTextColumnFilter",
                      },
                      {
                        field: "hcfcod",
                        width: 100,
                        headerName: "SPCB Code",
                        filter: "agTextColumnFilter",
                      },
                      {
                        field: "cty",
                        width: 100,
                        headerName: "City",
                        filter: "agTextColumnFilter",
                      },
                      {
                        field: "cntprsn",
                        width: 180,
                        headerName: "Contact Person",
                        filter: "agTextColumnFilter",
                      },
                      {
                        field: "phn",
                        width: 120,
                        headerName: "Mobile",
                        filter: "agTextColumnFilter",
                      },
                      {
                        field: "nobd",
                        width: 120,
                        headerName: "No of Beds",
                      },
                      {
                        field: "ltt",
                        width: 100,
                        headerName: "Latitude",
                      },
                      {
                        field: "lgt",
                        width: 100,
                        headerName: "Longtitude",
                      },
                      {
                        field: "addra",
                        width: 150,
                        headerName: "Address I",
                      },
                      {
                        field: "addrb",
                        width: 150,
                        headerName: "Address II",
                      },
                      {
                        field: "addrc",
                        width: 150,
                        headerName: "Address III",
                      },
                    ]}
                  ></LzListHCF>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/hcfbd"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzListHCF
                    path={"hcfbd"}
                    inputData={{ lvl: "CPCB", who: "", dtno: "0" }}
                    cols={[
                      // {
                      //   field: "cbwtfid",
                      //   hidden: true,
                      //   width: 50,
                      //   headerName: "S No",
                      // },
                      {
                        field: "hcfnm",
                        width: 320,
                        headerName: "HCF",
                        filter: "agTextColumnFilter",
                      },
                      {
                        field: "hcfcod",
                        width: 100,
                        headerName: "SPCB Code",
                        filter: "agTextColumnFilter",
                      },
                      {
                        field: "cty",
                        width: 100,
                        headerName: "City",
                        filter: "agTextColumnFilter",
                      },
                      {
                        field: "cntprsn",
                        width: 180,
                        headerName: "Contact Person",
                        filter: "agTextColumnFilter",
                      },
                      {
                        field: "phn",
                        width: 120,
                        headerName: "Mobile",
                        filter: "agTextColumnFilter",
                      },
                      {
                        field: "nobd",
                        width: 120,
                        headerName: "No of Beds",
                      },
                      {
                        field: "ltt",
                        width: 100,
                        headerName: "Latitude",
                      },
                      {
                        field: "lgt",
                        width: 100,
                        headerName: "Longtitude",
                      },
                      {
                        field: "addra",
                        width: 150,
                        headerName: "Address I",
                      },
                      {
                        field: "addrb",
                        width: 150,
                        headerName: "Address II",
                      },
                      {
                        field: "addrc",
                        width: 150,
                        headerName: "Address III",
                      },
                    ]}
                  ></LzListHCF>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/bhuvanmap"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzBhuvanMap />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/vehicletrack_map"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzVechilceTrackingMap />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/googlemap"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzGoogleMap />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>

          <Route
            path="/hcfnbd"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzListHCF
                    path={"hcfnbd"}
                    inputData={{ lvl: "CPCB", who: "", dtno: "0" }}
                    cols={[
                      // {
                      //   field: "cbwtfid",
                      //   hidden: true,
                      //   width: 50,
                      //   headerName: "S No",
                      // },
                      {
                        field: "hcfnm",
                        width: 320,
                        headerName: "HCF",
                        filter: "agTextColumnFilter",
                      },
                      {
                        field: "hcfcod",
                        width: 100,
                        headerName: "SPCB Code",
                        filter: "agTextColumnFilter",
                      },
                      {
                        field: "cty",
                        width: 100,
                        headerName: "City",
                        filter: "agTextColumnFilter",
                      },
                      {
                        field: "cntprsn",
                        width: 150,
                        headerName: "Contact Person",
                        filter: "agTextColumnFilter",
                      },
                      {
                        field: "phn",
                        width: 120,
                        headerName: "Mobile",
                      },
                      {
                        field: "hcftyp",
                        width: 70,
                        headerName: "HCF Type",
                      },
                      {
                        field: "ltt",
                        width: 100,
                        headerName: "Latitude",
                      },
                      {
                        field: "lgt",
                        width: 100,
                        headerName: "Longtitude",
                      },
                      {
                        field: "addra",
                        width: 150,
                        headerName: "Address I",
                      },
                      {
                        field: "addrb",
                        width: 150,
                        headerName: "Address II",
                      },
                      {
                        field: "addrc",
                        width: 150,
                        headerName: "Address III",
                      },
                    ]}
                  ></LzListHCF>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/hcflstgrd"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzGridDisply
                    path={"hcfregtdy"}
                    inputData={{ lvl: "CPCB", who: "", dtno: "0" }}

                  ></LzGridDisply>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/hcfcbwtf"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzHCFCnt
                    groupBy={"cbwtfid"}
                    mypage={1}
                    cols={[

                      {
                        field: "cbwtfnm",
                        width: 300,
                        headerName: "CBWTF",
                        tooltipField: "tphcf",
                        filter: "agTextColumnFilter",
                      },
                      {
                        field: "cty",
                        hidden: false,
                        width: 150,
                        headerName: "City",
                      },
                      {
                        field: "hcfcount",
                        width: 140,
                        headerName: "Health Care Facility",
                        tooltipField: "tphcf",
                      },
                      {
                        field: "beds",
                        width: 140,
                        headerName: "Total Beds",
                      },
                      {
                        field: "bedded",
                        width: 140,
                        headerName: "Bedded HCF",
                        tooltipField: "tpbd",
                      },
                      {
                        field: "nonbedded",
                        width: 140,
                        headerName: "Non Bedded HCF",
                        tooltipField: "tpnobd",
                      },
                      {
                        field: "state",
                        width: 150,
                        headerName: "State",
                      },
                      {
                        field: "rgd",
                        width: 200,
                        headerName: "RD",
                      },
                      {
                        field: "tphcf",
                        width: 150,
                        hide: true,
                        headerName: "",
                      },
                      {
                        field: "tpbd",
                        width: 150,
                        hide: true,
                        headerName: "",
                      },
                      {
                        field: "tpnobd",
                        width: 150,
                        hide: true,
                        headerName: "",
                      },
                    ]}
                  ></LzHCFCnt>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          {/* can use this page to show details */}
          <Route
            path="/hcfrgd"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzHCFCnt
                    groupBy={"rgd"}
                    mypage={2}
                    cols={[
                      {
                        field: "idsr",
                        hidden: true,
                        width: 100,
                        headerName: "S No",
                      },
                      {
                        field: "_id",
                        width: 400,
                        headerName: "RD",
                      },
                      {
                        field: "bedded",
                        width: 220,
                        headerName: "Bedded HCF ",
                      },
                      {
                        field: "nonbedded",
                        width: 100,
                        headerName: "Non Bedded HCF",
                      },
                      {
                        field: "hcfcount",
                        width: 250,
                        headerName: "Health Care Facility",
                      },
                      {
                        field: "beds",
                        width: 220,
                        headerName: "Total Beds",
                      },
                    ]}
                  ></LzHCFCnt>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/ListWrngHCFCode"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <LzWrongWstBgCbwtf />
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/wstbgs"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzListCbwtfWstData />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/cntr"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzCpcbHo />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>

          <Route
            path="/changePwd"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <LzChangePassword />
              </React.Suspense>
            }
          ></Route>

          <Route
            path="/cityHcfSearch"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzCityHcfList />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/rgnd"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzRgDrct />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/rgndlst"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzRgnDirtLst />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/sttdlst"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzSttDircctrLst />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/stt"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzStateBrd />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/hcfNonVisited"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzHcfNonVisited></LzHcfNonVisited>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>

          <Route
            path="/bagCntPrHr"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzBagCntPrHr></LzBagCntPrHr>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/bagCntPrGrid"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzBagCntPrHrGrid></LzBagCntPrHrGrid>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>

          <Route
            path="/bigBag"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzBigBag></LzBigBag>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/bagcntwthLbl"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzMisBagcount></LzMisBagcount>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/bagcntwthGeo"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzMisBagcntwthGeo></LzMisBagcntwthGeo>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/serialNumber"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzSerialNumber></LzSerialNumber>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/displayDataCard"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzDisplayDataCard></LzDisplayDataCard>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/HcfCrtList"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzHcfCorrectedList></LzHcfCorrectedList>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>


          <Route
            path="/HcfBlckusr"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzHcfUnblockUser></LzHcfUnblockUser>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>

          <Route
            path="/bagWtCntChrt2"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzBagWeightChartScnBy></LzBagWeightChartScnBy>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/bagWtCntChrt3"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzBagWeightChartScnBy50></LzBagWeightChartScnBy50>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/bagWtCntChrt4"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzBagWeightChart30></LzBagWeightChart30>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/bagWtCntChrt5"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzBagWeightChart50></LzBagWeightChart50>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/bagWtCntChrt6"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzBagWeightChart3050></LzBagWeightChart3050>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/bagWtCntChrt7"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzBagWeightChart3050Abv></LzBagWeightChart3050Abv>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/bagWtCntChrt8"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzBagWeightChartScnBy3050></LzBagWeightChartScnBy3050>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/bagWtCntChrt9"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzBagWeightChartScnBy3050Abv></LzBagWeightChartScnBy3050Abv>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/bagWtCntChrt10"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzBagWeightChartDate></LzBagWeightChartDate>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/bagCbwtfScnBy"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzBagCbwtfScnBy></LzBagCbwtfScnBy>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/bagFactory"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzBagFactory></LzBagFactory>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/WstbgOdd"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzWstbgOdd />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>


          <Route
            path="/hcfCtgCnt"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzHcfctgCnt></LzHcfctgCnt>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/hcfCtgGrid"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzHcfctgCntGrid></LzHcfctgCntGrid>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>


          <Route
            path="/helpPage"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzHelpPage></LzHelpPage>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>

          <Route
            path="/hcf_wstbg"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzHcf_Wstbg></LzHcf_Wstbg>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/srch_hcf"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzSrch_hcf></LzSrch_hcf>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/find_hcf"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzFind_HCF></LzFind_HCF>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/wstbgid"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzWstbgid></LzWstbgid>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/changePwdRgd"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzChangePswRgd></LzChangePswRgd>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/changePwdSpcb"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzChangePswSpcb></LzChangePswSpcb>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>

          <Route
            path="/cbwtfAnnulrptcp"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzCbwtfAnnulRpt />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/cbwtfAnnulrptcpcb"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzCbwtfAnnulRptCpcb />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>

          <Route
            path="/cbwtfMonthlyrptcpcb"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzCbwtfMonthlyRptCpcb />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/hcfAnnlRptcp"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzHcfAnnlPrtCpcb></LzHcfAnnlPrtCpcb>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/hcfMonthlyRptcp"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzHcfMonthlyPrtCpcb></LzHcfMonthlyPrtCpcb>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/hcfMonthlyhRpt"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzHcfMonthlyReport></LzHcfMonthlyReport>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>

          <Route
            path="/hcfMonthlyhRptFromTab"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzHcfMonthlyReportFormTab></LzHcfMonthlyReportFormTab>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/hcfAnnlRptcbcp"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <LzHcfAnnlPrt></LzHcfAnnlPrt>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/cbwtfdlyrep"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <Lzcbwtfdlyrep></Lzcbwtfdlyrep>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/loginFiles"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzLoginFiles></LzLoginFiles>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route> <Route
            path="/hospitalModified"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzHospitalModified></LzHospitalModified>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/hcfwstbg"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzClrHcfWst></LzClrHcfWst>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>

          <Route
            path="/dashboardvb"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzDashBoardNew />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>

          <Route
            path="/dailyReport"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzDailyReports />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/spcb_authorizationDetails"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  < LzAuthorizationDetail />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/spcb_authorizationAndWaste"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzAuthorizationAndWaste />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/spcb_authorizationAndconsolidate"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzAuthorizationAndConsoliDate />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/spcb_authorizationAndWasteCp"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzAuthorizationAndWasteCpcb />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>

          <Route
            path="/spcb_sttMonthlyRptCpcb"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzSttMonthlyRptCpcb />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>

          <Route
            path="/stt_monthlyReport"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzStateMonthlyReport />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/spcb_captiveInformation"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  < LzCaptiveInformation />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/spcb_wasteInformation"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  < LzWasteInformation />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>




          <Route
            path="/actvrpt"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzBrdActvSmry

                    cols={[
                      {
                        field: "cbwtfid",
                        hide: true,
                        width: 50,
                        headerName: "S No",
                      },
                      {
                        field: "cbwtfnm",
                        width: 400,
                        headerName: "CBWTF",
                        tooltipField: 'cbwtfnm',
                        filter: "agTextColumnFilter",
                      },
                      {
                        field: "state",
                        width: 250,
                        headerName: "State",
                        filter: "agTextColumnFilter",
                      },
                      {
                        field: "rgd",
                        width: 250,
                        headerName: "Regional Directorate",
                        filter: "agTextColumnFilter",
                      },
                      {
                        field: "tdyhcf",
                        width: 250,
                        headerName: "HCF Registered Today",
                        filter: "agTextColumnFilter",
                      },
                      {
                        field: "tdybags",
                        width: 250,
                        headerName: "Waste Bags Received",
                        filter: "agTextColumnFilter",
                        headerTooltip: "Sum of bags collected from HCF's, CBWTF and Plant",
                        tooltipField: "tdybagTool",
                      },
                      {
                        field: "route",
                        width: 120,
                        headerName: "Active Vehicles",
                        hide: true

                      }
                    ]}
                  ></LzBrdActvSmry>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>




          {/* <Route
            path="/spcb_cbwtf"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzAnnCbwtf></LzAnnCbwtf>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route> */}
          <Route
            path="/allStateReport"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzAllStateReport></LzAllStateReport>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          {/* <Route
            path="/spcb_frst"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzAnnlFrst></LzAnnlFrst>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/spcb_auth"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzStt_Annlauth />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/spcb_dstr"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzStt_Annldstr />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/spcb_hcfcptv"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzStt_Annlcptv />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route> */}

          <Route
            path="/annlRpt"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzAnnlRpt />
                  {/* <LzAllAnnlReport></LzAllAnnlReport> */}
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/annlMisc"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzAnnlMisc />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/annlEqp"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzAnnlEqp />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/annlWstStrg"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzAnnlWstStrg />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/annlWstWt"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzAnnlWstWt />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/hcfregister_indepent"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <LzAuthorHcfRegiterIndpnt></LzAuthorHcfRegiterIndpnt>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/hcfconsent_list"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <LzAuthorHcfConsentList></LzAuthorHcfConsentList>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/hcfconsent_report"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <LzAuthorHcfConsentReport></LzAuthorHcfConsentReport>
              </React.Suspense>
            }
          ></Route>

        </Route>
      </Routes>

      {/* <Tooltip title="Help" arrow>
        <IconButton
          color="primary"
          onClick={openHelpPage}
          sx={{
            position: "fixed",
            bottom: 40,
            right: 40,
            backgroundColor: "#007BFF",
            "&:hover": {
              backgroundColor: "#0056b3", // Change color on hover
            },
          }}
        >
          <HelpOutlineIcon style={{ color: "white" }} />
        </IconButton>
      </Tooltip> */}
    </div>
  );
}

export default React.memo(App);
