import React, { useEffect } from 'react'
import { useState } from 'react';
import { Outlet } from 'react-router';
import { useEffectOnce } from 'react-use';
// import Sidebar from '../app/MIS/sidebar';
import CustomSidebar from '../app/MIS/customSidebar'
import NewSidebar from '../app/MIS/NewSidebar'

import Topbar from '../app/MIS/topbar';

import { getApplicationVersion } from '../utilities/utilities';
import { getLvl, getWho } from '../utilities/cpcb';
import moment from 'moment';
import './switchLayout.css'
const SwitchLayout = () => {
    const [pageTitle, setPageTitles] = useState("Dashboard")
    const applicationOne: string = getApplicationVersion();
    const hideInState = getLvl() == 'STT' ? true : false;
    const hideInRgd = getLvl() == 'RGD' ? true : false;
    const hideInCbwtf = getLvl() == 'CPCB' ? false : true;
    const hideInHcf = getLvl() == 'HCF' ? true : false
    const hideInCpcb = getLvl() == 'CPCB' ? true : false

    const dt = moment(new Date()).format("DD-MMM-yyyy");

    const otherpage = [
        { path: "/spcb_frst", description: "State annual report: Details" },
        { path: "/spcb_cbwtf", description: "State annual report: CBWTF" },
        { path: "/spcb_auth", description: "State: Authorization Details " },
        { path: "/spcb_dstr", description: "State annual report:  District " },
        { path: "/spcb_hcfcptv", description: "State annual report: HCF Captive " },
        { path: "/annlRpt", description: "Annual report form : CBWTF" },
        { path: "/annlWstWt", description: "Annual report form : CBWTF" },
        { path: "/annlWstStrg", description: "Annual report form : CBWTF" },
        { path: "/annlEqp", description: "Annual report form : CBWTF" },
        { path: "/annlMisc", description: "Annual report form : CBWTF" },
    ]

    const statisticsArrMenu = [
        { name: 'Bags distribution wrt Scan By - Pie Chart', hide: false, path: "/bagWtCntChrt10", description: "Bags distribution based on date" },
        { name: 'Scan by Value', hide: true, path: "/bagWtCntChrt", description: "View details based on scan values" },
        { name: 'Bags distribution wrt Hospitals (Below/Above 30 Beds) - Pie Chart', hide: false, path: "/bagWtCntChrt4", description: "Bags distribution below/above 30 beds HCF based on scan values" },
        { name: 'Bags distribution wrt Distance (Below/Above 50 Mtr)  - Pie Chart', hide: false, path: "/bagWtCntChrt5", description: "Bags distribution based on distance below/above 50 meters based on scan values" },
        { name: 'Bags distribution wrt Hospitals (Below/Above 30 Beds)', hide: false, path: "/bagWtCntChrt2", description: "Explore bags list below/above 30 beds HCF based on scan values" },
        { name: 'Bags distribution wrt Distance (Below/Above 50 Mtr)', hide: false, path: "/bagWtCntChrt3", description: "Check the list of bags based on distance below/above 50 meters based on scan values" },
        { name: 'Bags distribution wrt Distance (Below/Above 50 Mtr) /(Below 30 Beds) - Pie Chart', hide: false, path: "/bagWtCntChrt6", description: "Bags distribution based on distance below/above 50 meters based below 30 beds" },
        { name: 'Bags distribution wrt Distance (Below/Above 50 Mtr) /(Above 30 Beds) - Pie Chart', hide: false, path: "/bagWtCntChrt7", description: "Bags distribution based on distance below/above 50 meters based On above 30 beds" },
        { name: 'Bags distribution wrt Distance (Below/Above 50 Mtr) /(Below 30 Beds) - Bar Chart', hide: false, path: "/bagWtCntChrt8", description: "Bags distribution based on distance below/above 50 meters based below 30 beds" },
        { name: 'Bags distribution wrt Distance (Below/Above 50 Mtr) /(Above 30 Beds) - Bar Chart', hide: false, path: "/bagWtCntChrt9", description: "Bags distribution based on distance below/above 50 meters based on above 30 beds" },
    ]

    const bagsReport = [
        { name: 'Live Waste Bag Collection', hide: true, path: "/trckbmw", description: "Real time waste collection (from midnight)" },
        { name: 'Waste Bags', hide: false, path: "/wstbgs", description: `List of waste bags on date: ${dt}` },
        { name: 'List of Correct Waste Bags Recived ', hide: false, path: "/HcfCrtList", description: "List of HCF who sent Correct Info" },
        { name: 'Bag Weight Dashboard ', hide: true, path: "/bagWtChrt", description: "Bag weight from bedded / non bedded hospital" },
        // { name: 'Bag Count Dashboard', hide: false, path: "/bagCntChrt", description: "Bag count from bedded / Non bedded Hospital" },
        { name: 'HCF Visited/Non Visited', hide: false, path: "/hcfNonVisited", description: "Count of HCF's visited/non-visited" },
        { name: 'Bag Count Per Hour (Chart View)', hide: false, path: "/bagCntPrHr", description: "Bag count per hour (chart view)" },
        { name: 'Bag Count Per Hour', hide: false, path: "/bagCntPrGrid", description: "Bag count per hour" },
        { name: 'Bags Above 25 Kg', hide: false, path: "/bigBag", description: "List of waste bag above 25 Kg " },
        { name: 'Waste Bag GeoLocation', hide: false, path: "/bagcntwthGeo", description: "Analyzing Waste Bag Scanning: Geolocation Accuracy Summary" },
        { name: 'Waste Bag Serial Number', hide: false, path: "/serialNumber", description: "Summary of waste bags with and without serial number" },
        { name: 'Waste Bag Label No', hide: false, path: "/bagcntwthLbl", description: "Summary of waste bag with and without Label Count" },
        { name: 'Waste Bag Odd Time Bags', hide: false, path: "/WstbgOdd", description: "Waste bag received at odd time i.e. from midnight to 8 AM  and after 8 PM" },
        { name: "CBWTF's daily bags count", hide: false, path: '/bagCbwtfScnBy', description: "CBWTF's daily bags count as per scan by values" },
        { name: "CBWTF bags at plant", hide: false, path: '/bagFactory', description: "CBWTF's daily bags count at plant level" },
        //{ name: 'Waste bag', hide: false, path: "/hcf_wstbg", description: "Search waste bags" },
        //{ name: "CBWTF's Daily Bags Count", hide: false, path: '/bagCbwtf', description: "CBWTF's Daily Bags Count" },
        //{ name: 'SPCB: Waste bag information', hide: !hideInState, path: "/spcb_wasteInformation", description: "SPCB: Waste bag information" },
        //{ name: "HCF's daily bags count", hide: false, path: '/bagCbwtfHcf', description: "HCF's daily bags count" },
        { name: 'Waste bag by id', hide: false, path: "/wstbgid", description: "Search waste bag by Id" },

    ]

    const operations = [
        // { name: 'CBWTF Info', hide: false, path: "/displayDataCard", description: "CBWTF information" },
        { name: 'CBWTF Daily Report', hide: false, path: "/cbwtfdlyrep", description: "Daily report of waste of CBWTF's" },
        // { name: 'Logins Based On File ', hide: false, path: "/loginFiles", description: "List of logins based on file" },
        // { name: 'Hospital Modified ', hide: false, path: "/hospitalModified", description: " list of hospital modified" },
        // { name: 'Hcf Dashboard', hide: false, path: "/bagHcfChrt", description: "HCF Dashboard" },
        { name: 'SPCB: Details', hide: !hideInState, path: "/stt", description: "SPCB: Details" },
        //{ name: 'SPCB: Authorization Form', hide: !hideInState, path: "/spcb_authorizationDetails", description: "SPCB: HCF Authorization Information" },
        // { name: 'SPCB: HCF-CBWTF Details Form', hide: !hideInState, path: "/spcb_captiveInformation", description: "SPCB: HCF-CBWTF Details Form" },
        { name: 'All State Boards', hide: true, path: "/sttall", description: "All state boards" },
        // { name: 'CBWTF Annual report', hide: false, path: "/annlrpt", description: "Annual report form : CBWTF" },
        { name: 'HCF Category Count (Chart View)', hide: false, path: "/hcfCtgCnt", description: "HCF category count (chart view) " },
        { name: 'HCF Category Count', hide: false, path: "/hcfCtgGrid", description: "HCF category count " },
        //{ name: 'HCF Visited', hide: false, path: "/hcfNmbrVisited", description: "Count of Visited HCF" },
        // { name: 'Hcf Acknowledgment', hide: false, path: "/acknowledgmentHcf", description: "Count of Hcf who's Acknowledgment Recevied And Not Recevied" },
        // { name: 'Monthly HCF Status', hide: false, path: "/monthlyStatus", description: "Monthly status of Health Care Facility" },
        // { name: 'Acivity on portal', hide: false, path: "/actvrpt", description: "CBWTF daily activity snapshot for a date" },
        { name: 'Map', hide: hideInRgd || hideInState, path: '/mapIndia', description: " Heat Map of India" },
        //{ name: 'Bedded Approximately  Ratio', hide: false, path: '/beddedRatio', description: "Ratio with respect to actual and expected value" },
        // { name: 'Search HCF', hide: false, path: "/srch_hcf", description: "Search Health care facility by CBWTF information" },
        // { name: 'Count HCF', hide: hideInState, path: "/rgdHcfSearch", description: "Count of HCF in regional directorate" },
        // { name: 'PNU ', hide: false, path: "/hcfWasteData", description: "Count of HCF visited by cbwtf" },
        { name: 'Daily Report: Detailed', hide: false, path: "/hcf_wstbg", description: "Waste collection report: daily detailed" },
        //{ name: 'Daily Report: Summary', hide: false, path: "/dailysummary", description: "Waste collection report: daily summary" },
        // { name: 'Daily Report: Route Wise', hide: false, path: "/dailySummaryRoute", description: "Daily summary: route wise" },
        // { name: 'Daily Summary: Plant (Comparison)', hide: false, path: "/dailysummaryfactoryComp", description: "Waste bags scanned at HCF / Plant (comparison)" },
        // { name: 'Daily Summary: Plant', hide: false, path: "/dailysummaryfactory", description: "Waste bags scanned at plant" },
        // { name: 'Daily Summary: Plant not scan', hide: false, path: "/dailysummaryfactorynotscn", description: "Waste bags not scanned at plant" },

    ]

    const mapMenu: any[] = [
        //    { name: 'Vehicle Tracking', hide: false, path: "/bhuvanmap", description: "Real-Time Tracking of Biomedical Waste Collection Vehicles" },
        { name: ' Vehicle Tracking', hide: false, path: "/vehicletrack_map", description: "Live GPS Monitoring for Biomedical Waste Management" },
    ]

    const annualFormReport = [
        { name: 'SPCB: Annual Report Form', hide: !hideInCpcb, path: "/spcb_authorizationAndWasteCp", description: "Annual report form : SPCB" },
        { name: 'SPCB: Annual Report Fill Form', hide: !hideInState, path: "/spcb_authorizationAndWaste", description: "Annual report form : SPCB" },
        { name: 'HCF Annual Report', hide: !hideInCpcb, path: "/hcfAnnlRptcp", description: "HCF Annual Report Form" },
        // { name: 'BMW-- Anuual Report', path: "/bmwtAnnulRpt", description: "BMW list" },
        // { name: 'Annual Form (CBWTF)', hide: false, path: "/allAnnlRpt", description: "Annual Report : CBWTF's" },
        // { name: 'State Annual Report', hide: hideInState, path: "/allStateReport", description: "State Annual Reports" },
        // { name: 'Annual Report', hide: false, path: "/annualRepA", description: "" },
        { name: 'CBWTF Annual Report', hide: !hideInCpcb, path: "/cbwtfAnnulrptcpcb", description: "CBWTF Annual Report Form" },
        { name: 'CBWTF & HCF consolidated data', hide: !hideInCpcb, path: "/spcb_authorizationAndconsolidate", description: "CBWTF & HCF consolidated data" },

    ]

    const sttAnnualRpt = [
        { name: 'HCF Annual Report', hide: !hideInState, path: "/hcfAnnlRptcp", description: "HCF Annual Report Form" },
        { name: 'SPCB Annual Report', hide: !hideInState, path: "/spcb_authorizationAndWaste", description: "SPCB Annual Report Form" },
        { name: 'CBWTF Annual Report', hide: !hideInState, path: "/cbwtfAnnulrptcpcb", description: "CBWTF Annual Report Form" },
        { name: 'CBWTF & HCF consolidated data', hide: !hideInState, path: "/spcb_authorizationAndconsolidate", description: "CBWTF & HCF consolidated data" },

    ]
    // const monthlyFormReport=[
    //     { name: 'SPCB: Monthly Report', hide: !hideInState , path: "/stt_monthlyReport", description: "Monthly report form : SPCB" },
    //     { name: 'SPCB: Monthly Report', hide: hideInState || hideInRgd || hideInRgd , path: "/spcb_sttMonthlyRptCpcb", description: "Monthly report form : SPCB" },
    //     { name: 'HCF: Monthly Report', hide: hideInRgd || hideInState, path: "/hcfMonthlyRptcp", description: "Monthly report form : HCF" },
    //     { name: 'CBWTF: Monthly Report', hide: hideInCbwtf || hideInRgd || hideInState, path: "/cbwtfMonthlyrptcpcb", description: "Monthly report form : CBWTF" },
    //     { name: 'HCF Monthly Report Show Form', hide:  !hideInHcf || hideInRgd || hideInState || hideInCpcb, path: "/hcfMonthlyhRptFromTab", description: "HCF Monthly Report Form" },
    // ]

    const rgdAnnualRpt = [
        { name: 'HCF Annual Report', hide: !hideInRgd, path: "/hcfAnnlRptcp", description: "HCF Annual Report Form" },
        { name: 'SPCB Annual Report', hide: !hideInRgd, path: "/spcb_authorizationAndWasteCp", description: "SPCB Annual Report Form" },
        { name: 'CBWTF Annual Report', hide: !hideInRgd, path: "/cbwtfAnnulrptcpcb", description: "CBWTF Annual Report Form" },
        { name: 'CBWTF & HCF consolidated data', hide: !hideInRgd, path: "/spcb_authorizationAndconsolidate", description: "CBWTF & HCF consolidated data" },


    ]

    const showProfile = [
        { name: 'Profile', hide: false, path: "/cntr", description: "Details of head office" },
        { name: 'Change Password RDs', hide: hideInState || hideInRgd || hideInHcf, path: "/changePwdRgd", description: "Change Password RDs" },
        { name: 'Change Password SPCBs', hide: hideInState || hideInRgd || hideInHcf, path: "/changePwdSpcb", description: "Change Password SPCBs" },
        { name: 'Change Password', hide: false, path: "/changePwd", description: "Change Password" },
        { name: 'Block User List', hide: hideInRgd || hideInState || hideInHcf, path: "/HcfBlckusr", description: "Blocked User List" },
    ]

    const DirectoryArr = [
        { name: 'RD', hide: hideInState || hideInRgd, path: "/rgndlst", description: "List of regional directorate" },
        { name: 'SPCB', hide: hideInState, path: "/sttdlst", description: "List of state pollution control board" },
    ]

    const monitoringCBWTF = [
        { name: 'CBWTF List', hide: false, path: "/cbwtfdspl", description: "List of CBWTF" },
        { name: 'CBWTF Info', hide: false, path: "/displayDataCard", description: "CBWTF information" },
        { name: 'HCF-CBWTF', hide: false, path: "/hcfcbwtf", description: "CBWTF (Click on the CBWTF to show the list of HCF)" },
    ]

    const monitoringHCF = [
        { name: 'Search HCF', hide: false, path: "/cityHcfSearch", description: "Search HCF city wise" },
        { name: 'HCF List', hide: false, path: "/hcflstgrd", description: "List of health care facility" },
        { name: 'Wrong Registering Attempt', hide: false, path: "/ListWrngHCFCode", description: "No of attempts of HCF registration with wrong SPCB code" },
        { name: 'Find HCF (by mobile)', hide: false, path: "/Find_HCF", description: "Search HCF by mobile no" },
        {name:"Register Independent HCF",hide :false,path:"/hcfregister_indepent",description:"Register Independent HCF List"},
        {name:"HCF Consent List",hide :false,path:"/hcfconsent_list",description:"HCF Consent List"},
        {name:"HCF Consent Report",hide :false,path:"/hcfconsent_report",description:"HCF Consent Details Report"}
    ]

    const hcfLoginMenu = [
        { name: 'HCF Details', hide: false, path: "/HcfDetails", description: "HCF Details" },
        { name: 'HCF Annual Report Show Form', hide: !hideInHcf || hideInRgd || hideInState || hideInCpcb, path: "/hcfAnnlRptFormTab", description: "HCF Annual Report Form" },
        { name: 'Change Password', hide: false, path: "/ChangePasswordHcf", description: "HCF  Change Password" },
    ]

    const hcfMenu: any[] = [
        //    { name: 'Vehicle Tracking', hide: false, path: "/bhuvanmap", description: "Real-Time Tracking of Biomedical Waste Collection Vehicles" },
        { name: 'HCF Details', hide: false, path: "/HcfDetails", description: "HCF Details" },
        { name: 'HCF Annual Report', hide: !hideInHcf || hideInRgd || hideInState || hideInCpcb, path: "/hcfAnnlRptFormTab", description: "HCF Annual Report Form" },
    ]

    const cbwtfMenu: any[] = [
        { name: 'CBWTF Details', hide: false, path: "/cbwtfDtl", description: "CBWTF Details" },
        { name: 'CBWTF Annual Report', hide: false, path: "/cbwtfAnnulRpt", description: "CBWTF Annual Report Form" },
        { name: 'Change Password', hide: false, path: "/ChangePasswordCbwtf", description: "CBWTF  Change Password" },
    ]


    useEffectOnce(() => {
        let pathName = window.location.pathname;

        annualFormReport.forEach((res: any) => {
            if (res.path == pathName) {
                setPageTitles(res.description)
            }
        })
        sttAnnualRpt.forEach((res: any) => {
            if (res.path == pathName) {
                setPageTitles(res.description)
            }
        })

        rgdAnnualRpt.forEach((res: any) => {
            if (res.path == pathName) {
                setPageTitles(res.description)
            }
        })


        showProfile.forEach((res: any) => {
            if (res.path == pathName) {
                setPageTitles(res.description)
            }
        })

        bagsReport.forEach((res: any) => {
            if (res.path == pathName) {
                setPageTitles(res.description)
            }
        })

        DirectoryArr.forEach((res: any) => {
            if (res.path == pathName) {
                setPageTitles(res.description)
            }
        })

        monitoringHCF.forEach((res: any) => {
            if (res.path == pathName) {
                setPageTitles(res.description)
            }
        })
        monitoringCBWTF.forEach((res: any) => {
            if (res.path == pathName) {
                setPageTitles(res.description)
            }
        })

        statisticsArrMenu.forEach((res: any) => {
            if (res.path == pathName) {
                setPageTitles(res.description)
            }
        })

        otherpage.forEach((res: any) => {
            if (res.path == pathName) {
                setPageTitles(res.description)
            }
        })

        cbwtfMenu.forEach((res: any) => {
            if (res.path == pathName) {
                setPageTitles(res.description)
            }
        })

        hcfMenu.forEach((res: any) => {
            if (res.path == pathName) {
                setPageTitles(res.description)
            }
        })

        hcfLoginMenu.forEach((res: any) => {
            if (res.path == pathName) {
                setPageTitles(res.description)
            }
        })
        mapMenu.forEach((res: any) => {
            if (res.path == pathName) {
                setPageTitles(res.description)
            }
        })

        operations.forEach((res: any) => {
            if (res.path == pathName) {
                setPageTitles(res.description)
            }
        })



    })


    const [showSideNav, setShowSideNav] = useState(false)

    const toggleSideNavOff = () => {
        setShowSideNav(false)
    }

    const toggleSideNavOn = () => {
        setShowSideNav(true)
    }


    return (
        <>
            {applicationOne === '2' ? <div className={`${showSideNav ? 'grid grid-cols-12' : ""} `}>
                <div className={`${showSideNav ? 'col-span-2' : "hidden"}`}>
                    {/* <div className='bg-white h-screen overflow-y-auto mr-2 hide-scrollbar' > */}
                    {/* <Sidebar toggleSideNavOff={toggleSideNavOff} setPageTitle={setPageTitles} dropdownOne={dropdownOne} dropdownChart={dropdownChart} dropdownTwo={dropdownTwo} dropdownThree={dropdownThree} dropdownFour={dropdownFour} dropdownFive={dropdownFive} dropdownSix={dropdownSix}></Sidebar> */}
                    {/* { <CustomSidebar toggleSideNavOff={toggleSideNavOff}  setPageTitle={setPageTitles} dropdownOne={dropdownOne} dropdownChart={dropdownChart} dropdownTwo={dropdownTwo} dropdownThree={dropdownThree} dropdownFour={dropdownFour} dropdownFive={dropdownFive} dropdownSix={dropdownSix} monthlyReport={monthlyReport} />} */}
                    <NewSidebar toggleSideNavOff={toggleSideNavOff} setPageTitle={setPageTitles} showProfile={showProfile} DirectoryArr={DirectoryArr} monitoringHCF={monitoringHCF} monitoringCBWTF={monitoringCBWTF} annualFormReport={annualFormReport} statisticsArrMenu={statisticsArrMenu} bagsReport={bagsReport} operations={operations} mapMenu={mapMenu} hcfLoginMenu={hcfLoginMenu} hcfMenu={hcfMenu} cbwtfMenu={cbwtfMenu} sttAnnualRpt={sttAnnualRpt} rgdAnnualRpt={rgdAnnualRpt} />
                    {/* </div> */}
                </div>
                <div className="col-span-10 h-screen overflow-y-auto ml-1">
                    <div className='sticky top-1 z-10 mr-2'>
                        <Topbar toggleSideNavOn={toggleSideNavOn} toggleSideNavOff={toggleSideNavOff} showSideNav={showSideNav} title={pageTitle} />
                    </div>
                    <div className='pb-12'>
                        <div className='mr-2'>
                            <Outlet />
                        </div>
                    </div>


                </div>
            </div> :
                <div>
                    <Outlet />
                </div>
            }

        </>
    )
}

export default React.memo(SwitchLayout)
